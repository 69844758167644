import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const WorkshopsPage = () => (
  <Layout>
    <SEO title="Workshopy" />

    <div className="container">
      <h1 className="pb-1">Workshopy</h1>

      <h3>Praktická pomoc nadšencům masáží</h3>

      <p>
        Máte ve svém okolí někoho, kdo rád masíruje?<br/>
        Naučíme se, jak masírovat za menšího úsilí a s větším výsledným efektem.<br/>
        Naučíme se několik druhů hmatů a tahů, které okamžitě využijete v praxi.
      </p>

      <p className="price">1&nbsp;500 Kč</p>

      <h3>Jak lehce začít s reflexní terapií</h3>

      <p>
        Pokud vám přijde Reflexní terapie moc složitá, uvidíte, jak lehké je začít jí praktikovat ve svém běžném, každodenním životě.<br/>
        Je vhodná jak preventivně, diagnosticky, tak i jako řešení problémů.
      </p>

      <p className="price">500 Kč</p>

      <h3>Masáž na zhubnutí a zpevnění doma</h3>

      <p>
        Proceduru v Salonu máme za 950,- Kč. Je nutné jí absolvovat nejméně osm, až desetkrát. Zde můžete ušetřit a zároveň se naučit, jak pečovat o své tělo.<br/>
        Naučíme se speciální masáž, která způsobí zpevnění stehen, zadečku i břicha. Zároveň dochází k úbytku cm.<br/>
        Dozvíte se také které účinné zábaly použít a kde je sehnat.
      </p>

      <p className="price">1&nbsp;890 Kč</p>

      <p className="text-center"><strong>Chcete-li se zúčastnit, prosím volejte na číslo <a href="tel:776009508">776 009 508</a> pro informace o termínu konání.</strong></p>
    </div>
  </Layout>
)

export default WorkshopsPage
